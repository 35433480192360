<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$t('deviceGateway.ESDT')" name="first">
      <device-gateway-list></device-gateway-list>
    </el-tab-pane>
    <el-tab-pane :label="$t('deviceGateway.EMT')" name="two">
      <device-gateway-insh-list></device-gateway-insh-list>
    </el-tab-pane>
    <el-tab-pane :label="$t('deviceGateway.NVR')" name="three">
      <device-gateway-tp-n-v-r-list></device-gateway-tp-n-v-r-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import DeviceGatewayList from "@/views/deviceGateway/DeviceGatewayList";
  import DeviceGatewayInshList from "@/views/deviceGateway/deviceGatewayInsh/DeviceGatewayInshList";
  import DeviceGatewayTpNVRList from "./deviceGatewayTpNVR/DeviceGatewayTpNVRList";

  export default {
    components: { DeviceGatewayList,DeviceGatewayInshList,DeviceGatewayTpNVRList },
    data() {
      return {
        activeName: "first",
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
