<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="deviceGatewayTpNVR">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('deviceGateway.onlineState')">
          <el-select
            v-model.trim="search.onlineStatus"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('100px', '100px') }"
            clearable>
            <el-option
              v-for="item in onlineStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <table-column
        prop="dtuCode"
        :label="$t('deviceGateway.dtuCode')"
        align="center">
      </table-column>
      <table-column prop="nodeCode" :label="$t('elevator.nodeCode')" align="center"></table-column>
      <table-column prop="onlineStatus" :label="$t('deviceGateway.dtuStatus')" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.onlineStatus === 0" type="info">
            {{$t("deviceGateway.dtuOffline")}}
          </el-tag>
          <el-tag v-if="scope.row.onlineStatus === 1" type="success">
            {{$t("deviceGateway.dtuOnline")}}
          </el-tag>
        </template>
      </table-column>
      <table-column prop="networkFormat" :label="$t('deviceGateway.networkFormat')" align="center"></table-column>
      <table-column prop="createTime" :label="$t('tenant.createTime')" align="center"></table-column>
      <table-column prop="lastUpdateTime" :label="$t('tenant.updateTime')" align="center"></table-column>
      <table-column :label="$t('elevatorNetApply.operate')" :width="300" align="center">
        <template #default="scope">
          <el-button type="text" @click="$refs.edit.open(scope.row.id)">
            {{$t("elevatorNetApply.edit")}}
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{$t("elevatorNetApply.delete")}}
          </el-button>
        </template>
      </table-column>
    </vm-table>
    <device-gateway-tp-n-v-r-edit ref="edit" @save-success="getList(-1)"></device-gateway-tp-n-v-r-edit>
  </div>
</template>
<script>
  import DeviceGatewayTpNVREdit from "./DeviceGatewayTpNVREdit";

  export default {
    components: {DeviceGatewayTpNVREdit},
    data() {
      return {
        adSearch: false,
        loading: true,
        search: {
          filter: "",
          onlineStatus: "",
        },
        onlineStateOptions: [
          {value: 0, label: this.$t("deviceGateway.dtuOffline")},
          {value: 1, label: this.$t("deviceGateway.dtuOnline")},
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNo) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNo);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("elevator.dtuCode") + " " + row.dtuCode + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {type: "warning"}).then(() => {
          this.$http.delete("deviceGatewayTpNVR", row.id).then(() => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
